import React, { useEffect, useState, useContext } from "react"
import { graphql } from "gatsby"

import "../../styles/pages/sunglasses.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlassesHeading from "../../components/GlassesHeading"
import Breadcrumbs from "../../components/Breadcrumbs"
import GlassesFilter from "../../components/GlassesFilter"
import GlassesList from "../../components/GlassesList"
import ProductBanner from "../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../services/hooks"
import CartContext from "../../context/cartContext"


const Sunglasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString, sunglassesAvailable }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern") &&
        sunglassesAvailable
      )
    }
  )

  const right_side_text =
    "Shine bright and find your perfect pair with our frames handcrafted for anyone under the sun. Starting at just $59.99 every pair of Hubble’s unisex sunglasses sits at the intersection of quality, comfort and style."
  const list_items = [
    "Quality first. Yep, anti-reflective, anti-glare, anti-scratch, hydrophobic and oleophobic coatings come standard on every single pair.",
    "Comfort Matters. With durable barrel hinges and padded temple tips for extra comfy cush, our unisex frames are optimized for everyday use.",
    "Personalized just for you. In addition to your custom prescription, add reader magnification to any pair of our sunnies.",
    "Designed for You. All of our sunglasses are made to order and hand-assembled. From unisex aviator sunglasses ready to take flight, to rocking retro cat-eye styles, you’re sure to find a pair for your personality.",
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: [],
    shape: [],
    material: [],
    color: [],
    gender: ["unisex"],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    setHeaderColor("green")

    return () => {
      setHeaderColor("black")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'sunglasses',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Sunglasses-Unisex`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  const schemaMarkup = [{
    "@url": "https://hubblecontacts.com/sunglasses/unisex-sunglasses",
    "@type": "webpage",
    "name": "Unisex Sunglasses: Prescription Sunglasses Online | Hubble",
    "description": "Explore our collection of stylish unisex sunglasses featuring polarized lenses that effectively block 99% of UVA/UVB rays. Discover distinctive frames that match your individual style.",
    "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
    }
  }]

  return (
    <Layout>
      <SEO
        title="Unisex Sunglasses: Prescription Sunglasses Online"
        description="Explore our collection of stylish unisex sunglasses featuring polarized lenses that effectively block 99% of UVA/UVB rays. Discover distinctive frames that match your individual style."
        schemaMarkup={schemaMarkup} />
      <div className="sunglasses-content">
        <GlassesHeading
          color="green"
          title="Unisex Sunglasses"
          description="Shop our stylish, designer unisex sunglasses with polarized lenses that block 99% of UVA/UVB rays and eliminate daytime glare. Discover frames as unique as you, all available with or without a prescription."
        />


        <div className="glasses-container">
          <div className="grid-section">
            <Breadcrumbs
              color="green"
              links={[{ to: "/", label: "Homepage" }, { label: "Sunglasses" }]}
            />
            <GlassesFilter
              type="sunglasses"
              glassesLength={sortedGlasses.length}
              filter={filter}
              setFilter={setFilter}
            />
            <GlassesList
              type="sunglasses"
              glasses={sortedGlasses}
              color={filter.color}
              gender={filter.gender[0]}
            />
            <ProductBanner
              title={"Unisex Sunglasses Frames"}
              right_side_text={right_side_text}
              list_items={list_items}
              optional_image="Hubble-Sunglasses-PCP-Inset-Lifestyle-Image.jpg"
              color="green"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sunglasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        sunglassesMaleHeaderImage1
        sunglassesFemaleHeaderImage1
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
